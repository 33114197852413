<template>
  <li class="faq-item" :class="{ active: isShown }" @click="isShown = !isShown">
    <div class="faq-item__header">
      <h3 class="faq-item__title">
        {{
          typeof item.title === "string"
            ? _T(item.title)
            : getLocalizedName(item.title)
        }}
      </h3>
      <div class="faq-item__icon-container">
        <span class="faq-item__icon" />
      </div>
    </div>
    <Transition name="expand-item">
      <div
        v-show="isShown"
        class="ui-rich-text"
        v-html="getLocalizedName(item.answer).content[0].content[0].value"
      />
    </Transition>
  </li>
</template>

<script setup>
const isShown = ref(false);

const props = defineProps({
  item: { type: Object, required: true },
});
</script>

<style lang="scss" scoped>
.faq-item {
  width: 100%;

  @include flex-container(column, flex-start);
  gap: 16px;

  padding: 16px;

  cursor: pointer;

  @include mobile {
    padding: 8px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-sky-light);
  }

  &:hover {
    & .faq-item__title {
      color: var(--color-primary-base);
    }

    & .faq-item__icon,
    & .faq-item__icon:before {
      background-color: var(--color-primary-base);
      transform: rotate(90deg) scale(1.1);
    }
  }

  &.active {
    border-radius: 16px;
    background-color: var(--color-sky-lightest);

    & .faq-item__icon:before {
      display: none;
    }

    &:hover {
      & .faq-item__icon {
        transform: rotate(180deg) scale(1.1);
      }
    }
  }

  &__header {
    @include flex-container(row, space-between, center);
  }

  &__title {
    @include font(18, 26, 600);

    transition: color 0.2s ease-in-out;

    @include mobile {
      @include font(16, 22, 600);
    }
  }

  &__icon-container {
    position: relative;

    @include fixedHW(32px);

    @include flex-container(row, center, center);

    padding: 8px;

    cursor: pointer;
  }

  &__icon {
    width: 16px;
    height: 2px;
    display: block;

    background-color: var(--color-ink-base);
    transition:
      transform 0.2s ease-in-out,
      background-color 0.2s ease-in-out;

    &:before {
      content: "";
      width: 16px;
      height: 2px;

      display: block;
      position: absolute;
      z-index: 2;

      background-color: var(--color-ink-base);

      transform: rotate(90deg);
      transition:
        transform 0.2s ease-in-out,
        background-color 0.2s ease-in-out;
    }
  }
}
</style>
